import React from "react"
import {useStaticQuery, graphql} from 'gatsby'

import './about.scss'
import Img from "gatsby-image"

import Icon1 from '../../svg/icon_1.svg'
import Icon2 from '../../svg/icon_2.svg'
import Icon3 from '../../svg/icon_3.svg'

const About = () => {
    const data = useStaticQuery(graphql`
        query {
            file: file(relativePath: { eq: "about-1.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 960) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            },
            file2: file(relativePath: { eq: "about-2.jpg" }) {
              childImageSharp {
                fixed(quality: 100, width: 250) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            file3: file(relativePath: { eq: "about-3.png" }) {
              childImageSharp {
                fixed(quality: 100, width: 300) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
        }
      `
    )

    return (
        <section className="about text-align-center" id="about">
            <Img fluid={data.file.childImageSharp.fluid} />
            <Img fluid={data.file2.childImageSharp.fixed} />
            <Img fluid={data.file3.childImageSharp.fixed} />

            <div className="container">
                <div className="text">
                    <p className="label">OBSAHUJE 20 BYLÍN</p>
                    <h2>Unikátny bylinný<br /> prsný balzam</h2>

                    <p>
                        Unikátny bylinný prípravok nahrádza osvedčený účinok nedostupného jazvečieho sadla, ktoré sa v tradičnom ľudovom liečiteľstve užívalo ako prsný balzam pre lepší priebeh odkašliavania. Prípravok obsahuje harmonicky vyváženú zmes 20 druhov bylinných výťažkov v BIO kokosovom masle. Jazvečia masť je vhodná na potieranie a masáž v oblasti hrudníka, krku, zátylku, chrbta, prípadne čela a spánkov.
                    </p>

                    <p>
                        <strong>Po masáži sa rýchlo uvoľňujú aktívne látky, ktoré:</strong>
                    </p>

                    <div className="icons flex justify-content-center">
                        <div className="item">
                            <Icon1 height="75" />
                            <h3>
                                Priaznivo pôsobia<br /> pri dýchacích ťažkostiach
                            </h3>
                        </div>


                        <div className="item">
                            <Icon2 height="75" />

                            <h3>
                                Uvoľňujú hlieny<br /> a zlepšujú odkašliavanie
                            </h3>
                        </div>

                        <div className="item">
                            <Icon3 height="75" />

                            <h3>
                                Pomáhajú uvoľňovať kašľom<br /> namáhané svalstvo
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
