import React from "react"
import {useStaticQuery, graphql} from 'gatsby'

import './effects.scss'
import Img from "gatsby-image"

const Effects = () => {
    const data = useStaticQuery(graphql`
        query {
            file: file(relativePath: { eq: "using.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 960) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            },
            file2: file(relativePath: { eq: "about-6.jpg" }) {
              childImageSharp {
                fixed(quality: 100, width: 250) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            },
            file3: file(relativePath: { eq: "about-5.jpg" }) {
              childImageSharp {
                fixed(quality: 100, width: 500) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            },
            file4: file(relativePath: { eq: "coconut.jpg" }) {
              childImageSharp {
                fixed(quality: 100, width: 200) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
        }
      `
    )

    return (
        <section className="effects" id="effects">
            <Img fluid={data.file2.childImageSharp.fixed} />
            <Img fluid={data.file3.childImageSharp.fixed} />

            <div className="container">
                <div className="text">
                    <div className="using flex align-items-center justify-content-right">
                        <div className="text">
                            <h3>Spôsob použitia</h3>
                            <p>
                                <strong>V oblasti hrudníka naneste</strong> dostatočné množstvo prípravku a dôkladne vmasírujte <strong>alebo pri silnejších ťažkostiach použite ako zábal. Pre zvýšenie účinnosti je vhodné balzam aplikovať aj v okolí nosných dierok, na krk, čelo, spánky i zátylok.</strong> Pre dosiahnutie maximálneho účinku je vhodné procedúru zopakovať niekoľkokrát denne.
                            </p>
                            <h3>O účinkoch je známe, že:</h3>
                            <ul>
                                <li><strong>priaznivo pôsobia pri dýchacích ťažkostiach</strong></li>
                                <li><strong>zlepšujú odkašliavanie a uvoľňujú hlieny</strong></li>
                                <li><strong>navodzujú pocit lepšieho dýchania</strong></li>
                                <li><strong>pomáhajú uvoľňovať kašľom namáhané svalstvo</strong></li>
                            </ul>
                        </div>
                        <div className="image">
                            <Img fluid={data.file.childImageSharp.fluid} />
                        </div>
                    </div>
                </div>
                <div className="text">
                    <Img fluid={data.file4.childImageSharp.fixed} />

                    <p className="label text-align-center">Účinné látky</p>
                    <h2 className="text-align-center">Harmonicky vyvážená<br /> zmes bylín</h2>

                    <div className="cols flex justify-content-center">
                        <div className="col">
                            <ul>
                                <li><span>Kokosové maslo</span></li>
                                <li><span>Shea butter</span></li>
                                <li><span>Mandľový olej</span></li>
                                <li><span>Slnečnicový olej</span></li>
                                <li><span>Makadamiový olej</span></li>
                            </ul>
                        </div>

                        <div className="col">
                            <ul>
                                <li><span>Eukalyptová silica</span></li>
                                <li><span>Kosodrevinová silica</span></li>
                                <li><span>Borievková silica</span></li>
                                <li><span>Mätová silica</span></li>
                                <li><span>Santalová silica</span></li>
                            </ul>
                        </div>

                        <div className="col">
                            <ul>
                                <li><span>Rozmarínová silica</span></li>
                                <li><span>Yzopová silica</span></li>
                                <li><span>Tymiánová silica</span></li>
                                <li><span>Borovicová silica</span></li>
                                <li><span>Lopúch väčší koreň</span></li>
                            </ul>
                        </div>

                        <div className="col">
                            <ul>
                                <li><span>Cédrová silica</span></li>
                                <li><span>Cyprus vždyzelený</span></li>
                                <li><span>Klinčeky</span></li>
                                <li><span>Harmanček pravý</span></li>
                                <li><span>Vrbová kôra</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Effects
