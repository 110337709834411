import React from "react"
import {useStaticQuery, graphql} from 'gatsby'

import './footer.scss'
import Img from "gatsby-image"

import Lidl from '../lidl/lidl'

import Icon1 from '../../svg/e-mail.svg'
import Icon2 from '../../svg/phone.svg'
import Icon3 from '../../svg/adresa.svg'
import Icon4 from '../../svg/spolecnost.svg'
import Icon5 from '../../svg/link.svg'

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            file2: file(relativePath: { eq: "footer-1.jpg" }) {
              childImageSharp {
                fixed(quality: 100, width: 400) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            file3: file(relativePath: { eq: "footer-2.jpg" }) {
              childImageSharp {
                fixed(quality: 100, width: 300) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
        }
      `
    )

    return (
        <footer className="text-align-center" id="contact">
            <Img fluid={data.file2.childImageSharp.fixed} />
            <Img fluid={data.file3.childImageSharp.fixed} />

            <div className="container">
                <div className="text">
                    <p className="label">Kontakt</p>
                    <h2>Spojte sa s nami</h2>

                    <div className="team flex justify-content-center">
                        <div className="item">
                            <h3>Lenka Klusková</h3>
                            <p className="position">Marketing, reklama, inzercia</p>

                            <a href="mailto:kluskova@herbamedicus.cz" className="flex align-items-center"><Icon1 height={14} width={30} />kluskova@herbamedicus.cz</a>
                        </div>

                        <div className="item">
                            <h3>Kamila Janik</h3>
                            <p className="position">Fakturácia, objednávky veľkoobchodov, reklamácie</p>

                            <a href="mailto:janik@herbamedicus.cz" className="flex align-items-center"><Icon1 height={14} width={30} />janik@herbamedicus.cz</a>
                        </div>

                        <div className="item">
                            <h3>Herbamedicus, s.r.o.</h3>

                            <p className="icon-left">
                                <Icon3 height={20} width={30} />
                                Ostružnická 325/6<br />
                                779 00 Olomouc
                            </p>

                            <p className="identifiers icon-left">
                                <Icon4 height={16} width={30} />

                                IČ: 27788601<br />
                                DIČ: CZ27788601
                            </p>

                            <p className="icon-left">
                                <Icon5 height={16} width={30} />
                                <a href="https://swissmedicus.ch/cz" target="_blank">swissmedicus.ch</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy">
                <div className="container">
                    &copy; Copyright 2020 Herbamedicus, s.r.o. | Všechna práva vyhrazena | Design by <a href="http://www.kantors.cz/" target="_blank" style={{color: '#000'}}>KANTORS</a> | Develop by <a href="https://www.valmia.cz" target="_blank">Valmia</a>.
                </div>
            </div>
        </footer>
    )
}

export default Footer
