import React from "react"
import {useStaticQuery, graphql} from 'gatsby'

import './parallax.scss'
import BackgroundImage from 'gatsby-background-image'

const Parallax = (props) => {
    const data = useStaticQuery(graphql`
        query {
            file: file(relativePath: { eq: "parallax-1.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            },
            file2: file(relativePath: { eq: "parallax-2.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
        }
      `
    )

    const imageData = props.image === 1 ? data.file.childImageSharp.fluid : data.file2.childImageSharp.fluid

    const label = props.label
    const title = props.title

    return (
        <BackgroundImage Tag="section" className="parallax text-align-center" fluid={imageData}>
            <div className="container">
                <div className="text">
                    <p className="label">{label}</p>
                    <h2 dangerouslySetInnerHTML={{ __html: title }} />
                </div>
            </div>
        </BackgroundImage>
    )
}

export default Parallax
