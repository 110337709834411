import React from "react"

import SEO from "../components/seo"

import './index.scss'

// import Lidl from "../components/lidl/lidl"
import Header from "../components/header/header"
import Cover from "../components/cover/cover"
import About from "../components/about/about"
import Parallax from "../components/parallax/parallax";
import Effects from "../components/effects/effects";
import Footer from "../components/footer/footer";
// import Modal from "../components/modal/modal";

const IndexPage = () => (
    <>
        <SEO title="Jazvečia Masť"/>
        <Header/>
        <Cover/>
        <About/>
        <Parallax label="Jazvečia Masť" title="Produkt na prírodnej báze,<br /> ktorý možno používať dlhodobo" image={1}/>
        <Effects/>
        <Parallax label="Jazvečia Masť" title="Balzam zložený z bylín<br /> zbieraných v lesoch" image={2}/>
        <Footer/>
    </>
)

export default IndexPage
